.lazy-load-image-background.blur > img {
  filter: blur(10px);
  opacity: 0;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  filter: blur(0);
  opacity: 1;
  transition: all 0.3s;
}
