.lazy-load-image-background.blur > img {
  filter: blur(10px);
  opacity: 0;
}

.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  filter: blur();
  opacity: 1;
  transition: all .3s;
}

/*# sourceMappingURL=index.56b15a43.css.map */
